<template>
	<div id="serve">
        <el-button> server</el-button>
	</div>
</template>

<script>
	export default {
		
	}
</script>
<style>
.serve {
    width: 100%;
    height: 100vh;
    background-color: black;

}
</style>
