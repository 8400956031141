<template>
    <div class="advertisement">
        <!-- 广告位1 -->
        <div id="adv1" v-if="typeKey == 1" style="text-align: center;background-color: #ccc;padding: 10px; display: flex;flex-direction: column; align-items: center;margin: 7px 0px 7px 0px;overflow: hidden;">
            <div style="text-align:center;color:black;font-size:13px;">advertisement</div>
            <!-- /21952429235,23082133079/be_toolol_300x250 -->
            <div id='div-gpt-ad-1720519368045-0' style='min-width: 300px; min-height: 250px;'></div>
        </div>
        <!-- 广告位2 -->
        <div id="adv1" v-if="typeKey == 2" style="text-align: center;background-color: #ccc;padding: 10px; display: flex;flex-direction: column; align-items: center;margin: 7px 0px 7px 0px;overflow: hidden;">
            <div style="text-align:center;color:black;font-size:13px;">advertisement</div>
            <!-- /21952429235,23082133079/be_toolol_336x280 -->
            <div id='div-gpt-ad-1720519416705-0' style='min-width: 336px; min-height: 280px;'></div>
        </div>
        <!-- 广告位3 -->
        <div id="adv1" v-if="typeKey == 3" style="text-align: center;background-color: #ccc;padding: 10px; display: flex;flex-direction: column; align-items: center;margin: 7px 0px 7px 0px;overflow: hidden;">
            <div style="text-align:center;color:black;font-size:13px;">advertisement</div>
            <!-- /21952429235,23082133079/be_toolol_300x250_1 -->
            <div id='div-gpt-ad-1720519583275-0' style='min-width: 300px; min-height: 250px;'></div>
        </div>
    </div>
	
</template>

<script>
	export default {
		name: 'App',
        props: {
            typeKey: {
                type: Number,
                default: 1
            }
        },
        mounted() {
            switch (this.typeKey) {
                case 1:
                    // 广告位函数
                    window.googletag = window.googletag || {cmd: []};
                    googletag.cmd.push(function() {
                        googletag.defineSlot('/21952429235,23082133079/be_toolol_300x250', [300, 250], 'div-gpt-ad-1720519368045-0').addService(googletag.pubads());
                        googletag.pubads().enableSingleRequest();
                        googletag.enableServices();
                    });
                    googletag.cmd.push(function() { googletag.display('div-gpt-ad-1720519368045-0'); });
                    break;

                case 2:
                    window.googletag = window.googletag || {cmd: []};
                    googletag.cmd.push(function() {
                        googletag.defineSlot('/21952429235,23082133079/be_toolol_336x280', [336, 280], 'div-gpt-ad-1720519416705-0').addService(googletag.pubads());
                        googletag.pubads().enableSingleRequest();
                        googletag.enableServices();
                    });
                    googletag.cmd.push(function() { googletag.display('div-gpt-ad-1720519416705-0'); });
                    break;
                    
                case 3:    
                    window.googletag = window.googletag || {cmd: []};
                    googletag.cmd.push(function() {
                        googletag.defineSlot('/21952429235,23082133079/be_toolol_300x250_1', [300, 250], 'div-gpt-ad-1720519583275-0').addService(googletag.pubads());
                        googletag.pubads().enableSingleRequest();
                        googletag.enableServices();
                    });
                    googletag.cmd.push(function() { googletag.display('div-gpt-ad-1720519583275-0'); });
                    break;
            }
         
               
        }
	}
</script>
<style>
</style>
