import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import Servace from '@/views/Serve/serve.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/about',
		name: 'about',
		component: AboutView,
		meta: {
			title: "关于",
		}
	},
	// 隐私协议
	{
		path: '/servace',
		name: 'servace-copy',
		component: Servace,
		meta: {
			title: "隐私协议",
		}
	},
	// {
	// 	path: '/new-page',
	// 	name: 'new-page',
	// 	component: () => import('../views/tool/NewPage/index.vue'),
	// 	meta: {
	// 		title: "快·起始页",
	// 	}
	// },
	{
		path: '/character-count',
		name: 'character-count',
		component: () => import('../views/tool/CharacterCount/index.vue'),
		meta: {
			title: "字符计数器",
		}
	},
	{
		path: '/text-comparison',
		name: 'text-comparison',
		component: () => import('../views/tool/TextComparison/index.vue'),
		meta: {
			title: "文本比较器",
		}
	},
	{
		path: '/sequence-handle',
		name: 'sequence-handle',
		component: () => import('../views/tool/SequenceHandle/index.vue'),
		meta: {
			title: "序列处理",
		}
	},
	{
		path: '/sakura-handle',
		name: 'sakura-handle',
		component: () => import('../views/tool/SakuraHandle/index.vue'),
		meta: {
			title: "分隔处理",
		}
	},
	{
		path: '/tablet',
		name: 'tablet',
		component: () => import('../views/tool/Tablet/index.vue'),
		meta: {
			title: "写字板",
		}
	},
	{
		path: '/img-base64',
		name: 'img-base64',
		component: () => import('../views/tool/ImgBase64/index.vue'),
		meta: {
			title: "图片转Base64",
		}
	},
	{
		path: '/img-exif',
		name: 'img-exif',
		component: () => import('../views/tool/ImgExif/index.vue'),
		meta: {
			title: "图片Exif信息查看",
		}
	},
	{
		path: '/img-compress',
		name: 'img-compress',
		component: () => import('../views/tool/ImgCompress/index.vue'),
		meta: {
			title: "图片压缩",
		}
	},
	{
		path: '/bmi',
		name: 'bmi',
		component: () => import('../views/tool/BMI/index.vue'),
		meta: {
			title: "BMI计算",
		}
	},
	{
		path: '/date-calculation',
		name: 'date-calculation',
		component: () => import('../views/tool/DateCalculation/index.vue'),
		meta: {
			title: "日期计算",
		}
	},
	{
		path: '/comprehensive-calculation',
		name: 'comprehensive-calculation',
		component: () => import('../views/tool/ComprehensiveCalculation/index.vue'),
		meta: {
			title: "综合计算",
		}
	},
	{
		path: '/decimal-conversion',
		name: 'decimal-conversion',
		component: () => import('../views/tool/DecimalConversion/index.vue'),
		meta: {
			title: "进制转换",
		}
	},
	{
		path: '/color-conversion',
		name: 'color-conversion',
		component: () => import('../views/tool/ColorConversion/index.vue'),
		meta: {
			title: "色值转换",
		}
	},
	{
		path: '/timestamp-conversion',
		name: 'timestamp-conversion',
		component: () => import('../views/tool/TimestampConversion/index.vue'),
		meta: {
			title: "时间戳转换",
		}
	},
	{
		path: '/device-detection',
		name: 'device-detection',
		component: () => import('../views/tool/DeviceDetection/index.vue'),
		meta: {
			title: "设备信息检测",
		}
	},
	{
		path: '/regex-tester',
		name: 'regex-tester',
		component: () => import('../views/tool/RegexTester/index.vue'),
		meta: {
			title: "正则表达式测试",
		}
	},
	{
		path: '/color-generator',
		name: 'color-generator',
		component: () => import('../views/tool/ColorGenerator/index.vue'),
		meta: {
			title: "色彩随机生成",
		}
	},
	{
		path: '/random-number',
		name: 'random-number',
		component: () => import('../views/tool/RandomNumber/index.vue'),
		meta: {
			title: "随机数生成",
		}
	},
	{
		path: '/random-string',
		name: 'random-string',
		component: () => import('../views/tool/RandomString/index.vue'),
		meta: {
			title: "随机字符串生成",
		}
	},
	{
		path: '/random-selector',
		name: 'random-selector',
		component: () => import('../views/tool/RandomSelector/index.vue'),
		meta: {
			title: "随机选择",
		}
	},
	{
		path: '/md5',
		name: 'md5',
		component: () => import('../views/tool/MD5/index.vue'),
		meta: {
			title: "MD5加密",
		}
	},
	{
		path: '/pbkdf2',
		name: 'pbkdf2',
		component: () => import('../views/tool/PBKDF2/index.vue'),
		meta: {
			title: "PBKDF2加密",
		}
	},
	{
		path: '/aes-des',
		name: 'aes-des',
		component: () => import('../views/tool/AES-DES/index.vue'),
		meta: {
			title: "AES/DES加密解密",
		}
	},
	{
		path: '/performance-test',
		name: 'performance-test',
		component: () => import('../views/tool/PerformanceTest/index.vue'),
		meta: {
			title: "页面性能测试",
		}
	},
	{
		path: '/clock',
		name: 'clock',
		component: () => import('../views/tool/Clock/index.vue'),
		meta: {
			title: "时钟",
		}
	},
	{
		path: '/quality-guarantee-period',
		name: 'quality-guarantee-period',
		component: () => import('../views/tool/QualityGuaranteePeriod/index.vue'),
		meta: {
			title: "保质期计算器",
		}
	},
	{
		path: '/stopwatch',
		name: 'stopwatch',
		component: () => import('../views/tool/Stopwatch/index.vue'),
		meta: {
			title: "秒表",
		}
	},
	{
		path: '/countdown',
		name: 'countdown',
		component: () => import('../views/tool/Countdown/index.vue'),
		meta: {
			title: "倒计时",
		}
	},
	{
		path: '/japanese-colors',
		name: 'japanese-colors',
		component: () => import('../views/tool/JapaneseColors/index.vue'),
		meta: {
			title: "日本传统色",
		}
	},
	{
		path: '/china-colors',
		name: 'china-colors',
		component: () => import('../views/tool/ChinaColors/index.vue'),
		meta: {
			title: "中国传统色",
		}
	},
	{
		path: '/temperature-conversion',
		name: 'temperature-conversion',
		component: () => import('../views/tool/TemperatureConversion/index.vue'),
		meta: {
			title: "温度单位转换",
		}
	},
	{
		path: '/weight-conversion',
		name: 'weight-conversion',
		component: () => import('../views/tool/WeightConversion/index.vue'),
		meta: {
			title: "重量单位转换",
		}
	},
	{
		path: '/speed-conversion',
		name: 'speed-conversion',
		component: () => import('../views/tool/SpeedConversion/index.vue'),
		meta: {
			title: "长度单位转换",
		}
	},
	{
		path: '/img-encapsulation',
		name: 'img-encapsulation',
		component: () => import('../views/tool/ImgEncapsulation/index.vue'),
		meta: {
			title: "图片包浆",
		}
	},
	{
		path: '/img-watermark',
		name: 'img-watermark',
		component: () => import('../views/tool/ImgWatermark/index.vue'),
		meta: {
			title: "图片加水印",
		}
	},
	{
		path: '/placement-synergy',
		name: 'placement-synergy',
		component: () => import('../views/tool/PlacementSynergy/index.vue'),
		meta: {
			title: "投放增效",
		}
	},
	{
		path: '/quick-copy',
		name: 'quick-copy',
		component: () => import('../views/tool/QuickCopy/index.vue'),
		meta: {
			title: "快速复制",
		}
	},


	
]

const router = new VueRouter({
	routes
})

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title + ' - Toolbox';
	} else {
		document.title = 'Toolbox';
	}
	next();
});

export default router