<template>
	<div class="service">
    <p>END USER LICENSE AGREEMENT</p>
        <p>Last updated on April 15, 2022</p>
        <p><br></p>
        <p>This end user license agreement (this “EULA”) is a legally binding agreement between you and Ongamingo and our Affiliates (as defined below) (“we”, “our” or “us”). By installing, using or otherwise accessing our services, you agree to the terms and conditions of this EULA. If you do not agree to any term of this EULA, please do not install, use or otherwise access our services. Your right to use our services is subject to your compliance with all of the terms and conditions set forth herein.</p>
        <p><br></p>
        <p>This EULA was written in English. To the extent any translated version conflicts with the English version, the English version controls.</p>
        <p><br></p>
        <p>This EULA applies specifically to:</p>
        <p><br></p>
        <p>-The registration and use of Your Account (as defined below);</p>
        <p><br></p>
        <p>-Your downloading, installing and/or using our games (including but not limited to PC games, web games, HTML5 games, mobile games, television/tablet games and any other form of games), including but not limited to any connected software, networks or services and any Updates (as defined below) we may make available to you (together, the “Software”);</p>
        <p><br></p>
        <p>-Your access to services that are made available by us in relation to the Software (the “Services”);</p>
        <p><br></p>
        <p>-Any Virtual Goods and Virtual Currency (each as defined below); and</p>
        <p><br></p>
        <p>-Any other text, images, graphics, photos, sounds, music, videos, audiovisual combinations, interactive content and any other media, content or information that may be made available to you through the Services (together, the “Content”, and together with the Software, Services, Virtual Goods, Virtual Currency, the “Licensed Items”).</p>
        <p><br></p><p>You must only use the Licensed Items:</p>
        <p><br></p>
        <p>-you are a “natural person” (corporations, limited liability companies, partnerships and other legal or
            business entities may not use the Licensed Items), and you are</p>
        <p><br></p>
        <p>not an individual specifically prohibited by us from using the Licensed Items;</p>
        <p><br></p>
        <p>-if you agree to this EULA;</p>
        <p><br></p>
        <p>-if you can lawfully enter into this EULA with us in accordance with applicable laws and regulations; and</p>
        <p><br></p>
        <p>-if applicable laws and regulations permit you to use the Licensed Items where you are when you use them.</p>
        <p><br></p>
        <p>You must comply with this EULA in your use of the Licensed Items and only use the Licensed Items as permitted
            by applicable laws and regulations, wherever you are when you use them. Please review this EULA and our
            policies and instructions to understand how you can and cannot use the Licensed Items.</p>
        <p><br></p>
        <p>If you are under the age of 13, you must not open an account and must not use any Licensed Items. If you are
            not an “adult” (as defined in the applicable in your jurisdiction), your parent or guardian must agree to
            this EULA (both for themselves and on your behalf) before you can use Your Account or any Licensed Items. If
            you are a parent or guardian, you accept responsibility for the user of Your Account and the Licensed Items,
            including but not limited to any payments, fees and charges relating to the Licensed Items, and you shall be
            responsible for monitoring and controlling the minors’ access to our Services if our Services are not
            suitable for minors pursuant to the applicable game rating systems.</p>
        <p><br></p>
        <p>YOUR PRIVACY</p>
        <p><br></p>
        <p>Our PRIVACY POLICY, which is a part of this EULA, sets out how we collect, store and use your personal
            information which you provide while you use Your Account, the Licensed Items and our Services.</p>
        <p><br></p>
        <p>CHANGES TO THE LICENSED ITEMS, THIS EULA AND THE PRIVACY POLICY</p>
        <p><br></p>
        <p>We may make changes to this EULA over time, so please come back and review them.</p>
        <p><br></p>
        <p>In addition, we may from time to time (and to the extent permitted by applicable laws and regulations) add,
            change or remove features from the Licensed Items (regardless of whether or not any particular Licensed Item
            is free of charge or not), or suspend or terminate use of the Licensed Item altogether.</p>
        <p><br></p>
        <p>By continuing to use the Licensed Items after we make any changes to this EULA, with or without notice from
            us, you are agreeing to be bound by the revised EULA.</p>
        <p><br></p>
        <p>LICENSE TO USE THE LICENSED ITEMS</p>
        <p><br></p>
        <p>The Licensed Items are property belonging to us and our licensors. They are protected by copyright laws and
            other intellectual property rights laws worldwide as set out in the “Our Intellectual Property Rights”
            section below.</p>
        <p><br></p>
        <p>Software License</p>
        <p><br></p>
        <p>Subject to the terms of this EULA, we grant you a limited, personal, non-exclusive, non-sublicensable,
            non-transferrable (except as provided below) and revocable license, under which you may:</p>
        <p><br></p>
        <p>-use the Software in object code format only on your own devices for your own personal and non-commercial use
            in accordance with any instructions, user guides, FAQs or other requirements specified by us from time to
            time (the “Instructions”) and this EULA; and</p>
        <p>-use the Services, subject to the “Services License” section below.</p>
        <p><br></p>
        <p>Services License</p>
        <p><br></p>
        <p>Where Services are made available as part of your use of Software under this EULA, we grant you a limited,
            personal, non-exclusive, non-sublicensable, non-transferrable and revocable License to use the Services in
            accordance with the Instructions and this EULA.</p>
        <p><br></p>
        <p>You may be able to create certain content within the Services, such as gameplay videos or additional game
            characters. Such content is subject to the “Your Content” section below.</p>
        <p><br></p>
        <p>Virtual Goods and Virtual Currency License</p>
        <p><br></p>
        <p>Where Software enables you to purchase or acquire Virtual Goods and/or Virtual Currency, we grant you a
            limited, personal, non-exclusive, non-sublicensable, non-transferrable and revocable License to use the
            Virtual Goods and Virtual Currency in accordance with the Instructions and this EULA, in particular the
            terms set out in the “Virtual Goods and Virtual Currency” section below.</p>
        <p><br></p>
        <p>Content License</p>
        <p><br></p>
        <p>Under the terms of this EULA we grant you a limited, personal, non-exclusive, non-sublicensable, revocable
            right to access and use the Content through the means provided in the Software or the relevant Service for
            your use.</p>
        <p><br></p>
        <p>Your Content</p>
        <p><br></p>
        <p>When you submit, upload, transmit or display any data, information (other than your personal information,
            which we will collect, use and store pursuant to terms of the Privacy Policy), media or other content in
            connection with your use of our Services (“Your Content”), you understand and agree that:</p>
        <p><br></p>
        <p>(1)you will continue to own and be responsible for Your Content;</p>
        <p><br></p>
        <p>(2)you are giving us and our Affiliates the right to use Your Content (with no fees or charges payable by us
            to you) including but not limited to the right to create derivative works of, to publicly display, to
            reproduce, and to publicly perform Your Content, for the purposes of providing, promoting, developing and
            trying to improve our Services, including but not limited to our relevant Services that you submitted Your
            Content in and any other services that we may provide now or in the future;</p>
        <p><br></p>
        <p>(3)we may use the name that you submit in connection with Your Content (whether that be Your Account name,
            real name or otherwise);</p>
        <p><br></p>
        <p>(4)in using Your Content for these purposes, we and our Affiliates may copy, reproduce, host, store, process,
            adapt, modify, translate, perform, distribute and publish Your Content worldwide in all media and by all
            distribution methods, including but not limited to those that are developed in the future, provided that
            they are incorporated into our Services which you use;</p>
        <p><br></p>
        <p>(5)we may share Your Content with third parties that we work with to help provide, promote, develop and
            improve our Services, but we will not sell Your Content to such third parties (other than our Affiliates)
            for their use for their own purposes (i.e. for any purposes that are not related to our Services); and</p>
        <p><br></p>
        <p>(6)you will comply with the Instructions and this EULA, in your submission of Your Content.</p>
        <p><br></p>
        <p>In addition, you agree that we and our Affiliates (subject to this EULA, our Privacy Policy and applicable
            laws and regulations):</p>
        <p><br></p>
        <p>-are allowed to retain and continue to use Your Content after you stop using our Services - for example,
            where you have shared Your Content with other users of our Services;</p>
        <p><br></p>
        <p>-may be required to retain or disclose Your Content in order to comply with applicable laws or regulations,
            or in order to comply with a court order, subpoena or other legal process, or we and our Affiliates may
            disclose Your Content in response to a lawful request by a government authority, law enforcement agency or
            similar body (whether situated in your jurisdiction or elsewhere); and</p>
        <p><br></p>
        <p>-may need to disclose Your Content (including but not limited to any information relating to Your Account) in
            order to enforce this EULA, protect our rights, property or safety, or the rights, property or safety of our
            Affiliates or other users of our Services.</p>
        <p><br></p>
        <p>You understand that even if you seek to delete Your Content from our Services, it may as a technical and
            administrative matter take some time or not be possible to achieve this – for example, we may not be able to
            prevent any third party from storing or using any of Your Content that you have made public via our
            Services.</p>
        <p><br></p>
        <p>We reserve the right to block or remove Your Content for any reason, including but not limited to as is in
            our opinion appropriate or as required by applicable laws and regulations.</p>
        <p><br></p>
        <p>“Affiliate(s)” with respect to a legal entity (such as a corporation, partnership, or limited liability
            company) shall mean any other legal entity that controls, is controlled by or, is under common control with
            such legal entity. For the purposes of this definition, the term “control” means the possession, directly or
            indirectly, of the power to direct or cause the direction of the management and policies of such legal
            entity, whether through the ownership of voting securities or by contract.</p>
        <p><br></p>
        <p>Responsibility for Your Content</p>
        <p><br></p>
        <p>You are solely responsible for Your Content and we recommend that you keep a back-up copy of it at all times.
            You must at all times ensure that: (i) you have the rights required to submit, transmit or display Your
            Content, and to grant us the rights as set out in this EULA; and (ii) Your Content (and our use of Your
            Content in accordance with this EULA) does not infringe or violate the rights of any person or otherwise
            contravene any applicable laws or regulations.</p>
        <p><br></p>
        <p>MONITORING AND PROHIBITED ACTIVITIES</p>
        <p><br></p>
        <p>We have no obligation to monitor Your Content and we are not responsible for monitoring the Services for
            inappropriate or illegal Content or conduct by other players. However, we do reserve the right, in our sole
            discretion, to monitor and/or record your interactions with the Services and other players (including but
            not limited to chat text and voice communications) when you are using the Services.</p>
        <p><br></p>
        <p>You agree not to engage in any of the following prohibited activities on or in relation to our Services, or
            allow any person to use Your Account to do the same:</p>
        <p><br></p>
        <p>(1)impersonate any person or misrepresent your affiliation with any person or entity in registering an
            account (including but not limited to by creating a misrepresentative account name or accessing another
            user’s account) or in making any communications or sharing or publishing any content or information using
            our Services;</p>
        <p><br></p>
        <p>(2)name squat via Your Account name in a way that infringes any third party’s intellectual property rights or
            other rights;</p>
        <p><br></p>
        <p>(3)send any unsolicited, unauthorized spam (such as spam comments in our social media services), advertising
            or promotional messages, or any other commercial communications;</p>
        <p><br></p>
        <p>(4)use our Services for any commercial purpose or for the benefit of any third party, except as expressly
            permitted by this EULA or otherwise authorized by us from time to time, including but not limited to any of
            the following activities:</p>
        <p><br></p>
        <p>-use our Services for any esports or group competition sponsored, promoted or facilitated by any commercial
            or non-profit entity;</p>
        <p><br></p>
        <p>-making postings about a product or service offered by you or by any company in which you hold a direct or
            indirect interest;</p>
        <p><br></p>
        <p>-making postings about a product or service that directly competes with a product or service offered by you
            or by any company in which you hold a direct or indirect interest;</p>
        <p><br></p>
        <p>-making postings (whether written by you or anyone else) in exchange for money or other benefits or
            compensations from any third party;</p>
        <p><br></p>
        <p>-posting information about product or shipping availability from third party suppliers, whether such third
            parties are in competition with us or not; or</p>
        <p><br></p>
        <p>-making any other postings not in good faith;</p>
        <p><br></p>
        <p>(5)submit, upload, transmit or display, through our Services, any content (whether displayed publicly or not,
            and whether displayed directly or indirect to any other users) which in fact or in our reasonable
            opinion:</p>
        <p><br></p>
        <p>-breaches any laws or regulations (or may result in a breach of any laws or regulations when used in a manner
            permitted by this EULA);</p>
        <p><br></p>
        <p>-creates a risk of loss or damage to any person or property;</p>
        <p><br></p>
        <p>-is fraudulent, false, misleading or deceptive;</p>
        <p><br></p>
        <p>-harms or exploits any person (whether adult or minor) in any way, including but not limited to via bullying,
            harassment or threats of violence;</p>
        <p><br></p>
        <p>-is hateful, harassing, abusive, racially or ethnically offensive, defamatory, humiliating to other people
            (publicly or otherwise), threatening, profane or otherwise objectionable;</p>
        <p><br></p>
        <p>-promotes or encourages self-harming;</p>
        <p><br></p>
        <p>-infringes our rights or any third party’s rights, including but not limited to any intellectual property
            rights, contractual rights, confidentiality rights or privacy rights;</p>
        <p><br></p>
        <p>-is pornographic, sexually explicit, violent or otherwise of an indecent or offensive nature; or</p>
        <p><br></p>
        <p>-encourages or is likely to encourage any of the above;</p>
        <p><br></p>
        <p>(6)violate any applicable laws or regulations, including but not limited to credit card fraud or bank account
            fraud;</p>
        <p><br></p>
        <p>(7)engage in any illegal or potentially illegal (as determined by us) activities or transactions, including
            but not limited to sale of any illicit drugs or money laundering;</p>
        <p><br></p>
        <p>(8)gamble, provide gambling information or entice others to engage in gambling through any method;</p>
        <p><br></p>
        <p>(9)use or exploit any of our intellectual property rights (including but not limited to our trademarks, brand
            name, game character design, music, image, video, storyline, logo, domain names, distinctive brand features,
            and any other of our proprietary information or the layout or design of any page), or otherwise infringe on
            any of our intellectual property rights (including but not limited to attempting to reverse engineer any
            applications or software used to access our Services);</p>
        <p><br></p>
        <p>(10)access any of our Services, collect or process any content made available through our Services, send or
            redirect any communications through our Services, in each case, through the use any automated bots,
            software, engines, crawlers, scrapers, data mining tools or the like, or attempt to do any of the foregoing,
            including but not limited to using any automated means to artificially promote any content;</p>
        <p><br></p>
        <p>(11)engage in any “framing”, “mirroring,” or other techniques directed at simulating the appearance or
            function of our Services;</p>
        <p><br></p>
        <p>(12)interfere with, or attempt to interfere with, any user’s or any other party’s access to our Services;</p>
        <p><br></p>
        <p>(13)intentionally distribute viruses, worms, Trojan horses, corrupted files or other malicious code or
            items;</p>
        <p><br></p>
        <p>(14)share or publish any other person’s personally identifiable information using our Services without their
            express consent;</p>
        <p><br></p>
        <p>(15)probe or test the vulnerability of, or otherwise circumvent (or attempt to circumvent) any security
            features on, our Services, our systems or the systems of other users;</p>
        <p><br></p>
        <p>(16)create multiple accounts for disruptive or abusive purposes; or</p>
        <p><br></p>
        <p>(17)engage in any other activity that encourages any person or entity to breach this EULA.</p>
        <p><br></p>
        <p>RESTRICTIONS ON YOUR USE OF THE LICENSED ITEMS</p>
        <p><br></p>
        <p>You may not, nor may you permit any other person to:</p>
        <p><br></p>
        <p>(1)use the Licensed Items in any manner or for any purpose which breaches the terms of this EULA or
            contravenes any applicable laws and regulations;</p>
        <p><br></p>
        <p>(2)use the Licensed Items to gain unauthorized access to any system, account or data;</p>
        <p><br></p>
        <p>(3)sub-license, rent, lease or sell the Licensed Items;</p>
        <p><br></p>
        <p>(4)directly or indirectly charge others for use or access to the Licensed Items;</p>
        <p><br></p>
        <p>(5)directly or indirectly suggest our support or endorsement of any product, service or content (including
            but not limited to any personal web site);</p>
        <p><br></p>
        <p>(6)transmit unauthorized communications through use of the Licensed Items, including but not limited to junk
            mail or spam;</p>
        <p><br></p>
        <p>(7)make the Licensed Items publicly available or available on any network for copying, download or use by any
            person or persons;</p>
        <p><br></p>
        <p>(8)remove, obscure or modify any copyright, trademark or other proprietary rights notice, marks or
            indications found in or on the Licensed Items;</p>
        <p><br></p>
        <p>(9)misrepresent the source or ownership of the Licensed Items;</p>
        <p><br></p>
        <p>(10)copy, reproduce, adapt, modify, translate or create derivate works from the Licensed Items, lend, hire,
            rent, perform, sub-license, make available to the public, broadcast, distribute, transmit or otherwise use
            any Licensed Item in whole or in part in any manner not expressly permitted by this EULA, or attempt to do
            any of the foregoing;</p>
        <p><br></p>
        <p>(11)attempt to disrupt or interfere with the Licensed Items including but not limited to manipulating the
            legitimate operation of the Licensed Items;</p>
        <p><br></p>
        <p>(12)use cheats, exploits, automation software or any unauthorized third party software designed to modify or
            interfere with the Licensed Items;</p>
        <p><br></p>
        <p>(13)disrupt or overburden any computer or server used to offer or support the Licensed Items, or other users’
            use of the Licensed Items; or</p>
        <p><br></p>
        <p>(14)develop any plug-ins, external components, compatibles or interconnection elements or other technology
            that inter-operate with the Licensed Items, except where we expressly permit you to do so via our Software
            (and where this is the case, your use of such Software may be subject to additional terms and conditions as
            notified by us to you).</p>
        <p><br></p>
        <p>Please note that there may be technological measures in the Licensed Items that are designed to prevent
            unlicensed or unauthorized use of the Licensed Items or use of the Licensed Items in breach of terms or this
            EULA. You agree that we may use these measures and that you will not seek to disable or circumvent them in
            any way.</p>
        <p><br></p>
        <p>INSTALLATION AND USE</p>
        <p><br></p>
        <p>You must ensure that your device meets the requirements for installing and using the Licensed Items set out
            in the Instructions, including but not limited to any requirements as to operating system and
            compatibility.</p>
        <p><br></p>
        <p>During installation, the Licensed Items may uninstall or disable other software running on your device.
            Depending on the Licensed Item, you may be required to activate the Licensed Item or certain functionality
            in it in the manner described in the applicable Instructions. If you do not comply with the installation
            instructions, you may not be able to use the Licensed Item or certain functionality may not be available to
            you. Your use of the Licensed Item may require an internet connection in order to authenticate the Licensed
            Item or perform other functions. In order for the functionality and/or performance expected of the Licensed
            Item to be achieved, you may be required to obtain and maintain an adequate internet connection.</p>
        <p><br></p>
        <p>You understand that if you change devices or data is erased or wiped from your device, Your Content, game
            progress data, your Virtual Currency and your Virtual Goods may only be accessible through the retrieval
            methods we make available to Your Account from time to time.</p>
        <p><br></p>
        <p>YOUR ACCOUNT</p>
        <p><br></p>
        <p>You may need to create an account with us in order to access and use some elements of the Software (“Your
            Account”).</p>
        <p><br></p>
        <p>Your Account is personal to you and you are prohibited from gifting, lending, transferring or otherwise
            permitting any other person to access or use Your Account. Your Account name, user ID and other identifiers
            you adopt within our Services remains our property and we can disable, reclaim and reuse these once Your
            Account is terminated or deactivated for whatever reason by either you or us.</p>
        <p><br></p>
        <p>You are responsible for: (i) safeguarding the details of Your Account, including but not limited to any
            passwords used to access Your Account and our Services, and (ii) all use of our Services under Your Account.
            You must promptly notify us if you know or suspect that Your Account or its password have been compromised.
            We will regard all use of Your Account as being by you, except where we have received and acknowledged your
            notification to us regarding Your Account and/or its password being compromised.</p>
        <p><br></p>
        <p>If you fail to safeguard the details of Your Account, you agree to be solely responsible for all relevant
            losses, including but not limited to the loss of Virtual Goods and Virtual Currency.</p>
        <p><br></p>
        <p>We may take certain technical measures to verify that you are the owner of Your Account from time to time,
            especially when you intend to take certain major actions, such as changing your password, or deleting your
            Account. Such technical measures may include (i) sending verification code to your cell phone or email
            address, and (ii) requesting you to provide additional information with respect to Your Account or your use
            of our Services.</p>
        <p><br></p>
        <p>UPDATES AND CHANGES TO SOFTWARE AND SERVICES</p>
        <p><br></p>
        <p>We may, in our discretion, distribute corrections, updates, upgrades and new versions of the Software and/or
            the Services (each an “Update”). You agree to receive and permit us to deliver Updates to your device, and
            you acknowledge that, if you do not accept and install the Update, the Software and/or the Services may no
            longer operate with the full functionality or performance described in the Instructions or operate at
            all.</p>
        <p><br></p>
        <p>We reserve the right, in our discretion, to change or limit the functionality and performance of the Software
            and/or the Services, including but not limited to withdrawing support or access to the Software and/or the
            Services, and to prevent the use of any Software and/or Services in relation to which all available Updates
            have not been installed.</p>
        <p><br></p>
        <p>Such updates may occur automatically or manually. Please note that the Software and/or the Services may not
            operate properly or at all if upgrades or new versions are not installed by you. We do not guarantee that we
            will make any updates available for any of our Software or Services, or that such updates will continue to
            support your device or system.</p>
        <p><br></p>
        <p>WARRANTY AND DISCLAIMER</p>
        <p><br></p>
        <p>We warrant to you that we will provide our Services using reasonable care and skill.</p>
        <p><br></p>
        <p>APART FROM THE WARRANTY IN THE PRECEDING SENTENCE, TO THE EXTENT PERMITTED BY APPLICABLE LAWS AND
            REGULATIONS, ALL OF OUR SERVICES AND SOFTWARE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND
            NEITHER US NOR ANY OF OUR AFFILIATES MAKE ANY REPRESENTATION OR WARRANTY OR GIVES ANY UNDERTAKING IN
            RELATION TO OUR SERVICES, OUR SOFTWARE OR ANY CONTENT SUBMITTED, TRANSMITTED OR DISPLAYED BY OUR SERVICES,
            INCLUDING BUT NOT LIMITED TO: (I) ANY REPRESENTATION, WARRANTY OR UNDERTAKING THAT OUR SERVICES OR SOFTWARE
            WILL BE UNINTERRUPTED, SECURE OR ERROR-FREE OR FREE FROM VIRUSES; (II) THAT OUR SERVICES OR SOFTWARE WILL BE
            COMPATIBLE WITH YOUR DEVICE; OR (III) THAT OUR SERVICES OR SOFTWARE WILL BE OF MERCHANTABLE QUALITY, FIT FOR
            A PARTICULAR PURPOSE OR NOT INFRINGE THE INTELLECTUAL PROPERTY RIGHTS OF ANY PERSON. TO THE EXTENT PERMITTED
            BY APPLICABLE LAWS AND REGULATIONS, YOU WAIVE ANY AND ALL IMPLIED REPRESENTATIONS, WARRANTIES AND
            UNDERTAKINGS.</p>
        <p><br></p>
        <p>LIABILITY FOR OUR SERVICES</p>
        <p><br></p>
        <p>TO THE EXTENT PERMITTED BY APPLICABLE LAWS AND REGULATIONS, THE TOTAL AGGREGATE LIABILITY OF US AND OUR
            AFFILIATES FOR ALL CLAIMS IN CONNECTION WITH THIS EULA OR OUR SERVICES OR SOFTWARE, ARISING OUT OF ANY
            CIRCUMSTANCES, WILL BE LIMITED TO THE LOWER OF THE FOLLOWING AMOUNTS: (I) THE AMOUNT THAT YOU HAVE PAID TO
            US FOR YOUR USE OF THE SPECIFIC SERVICE OR SOFTWARE TO WHICH THE CLAIM RELATES IN THE 6 MONTHS IMMEDIATELY
            PRECEDING THE DATE OF THE MOST RECENT CLAIM; AND (II) USD$100.</p>
        <p><br></p>
        <p>TO THE EXTENT PERMITTED BY APPLICABLE LAWS AND REGULATIONS, IN NO EVENT WILL WE OR ANY OF OUR AFFILIATES BE
            LIABLE IN CONNECTION WITH THIS EULA OR OUR SERVICES OR SOFTWARE FOR ANY DAMAGES CAUSED BY: (I) ANY NATURAL
            DISASTER SUCH AS FLOODS, EARTHQUAKES OR EPIDEMICS; (II) ANY SOCIAL EVENT SUCH AS WARS, RIOTS OR GOVERNMENT
            ACTIONS; (III) ANY COMPUTER VIRUS, TROJAN HORSE OR OTHER DAMAGE CAUSED BY MALWARE OR HACKERS; (IV) ANY
            MALFUNCTION OR FAILURE OF OUR OR YOUR SOFTWARE, SYSTEM, HARDWARE OR CONNECTIVITY; (V) IMPROPER OR
            UNAUTHORISED USE OF OUR SERVICES OR SOFTWARE; (VI) YOUR USE OF OUR SERVICES OR SOFTWARE IN BREACH OF THIS
            EULA; OR (VII) ANY REASONS BEYOND OUR REASONABLE CONTROL OR PREDICTABILITY. NOR WILL WE, TO THE EXTENT
            PERMITTED BY APPLICABLE LAWS AND REGULATIONS, BE LIABLE UNDER ANY CIRCUMSTANCES FOR ANY INDIRECT, SPECIAL,
            CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES OR FOR ANY LOSS OF BUSINESS, REVENUES, PROFITS, GOODWILL,
            CONTENT OR DATA.</p>
        <p><br></p>
        <p>Nothing in this EULA limits or excludes any of the following liabilities, except to the extent that such
            liabilities may be waived, limited or excluded under applicable laws and regulations:</p>
        <p><br></p>
        <p>-any liability for death or personal injury;</p>
        <p><br></p>
        <p>-any liability for gross negligence or willful misconduct; or</p>
        <p><br></p>
        <p>-any other liability to the extent that such liability cannot be waived, limited or excluded under applicable
            laws and regulations.</p>
        <p><br></p>
        <p>NOTWITHSTANDING ANY OTHER PROVISIONS OF THIS EULA, NOTHING IN THIS EULA LIMITS OR EXCLUDES ANY OF YOUR
            STATUTORY RIGHTS IN YOUR JURISDICTION (INCLUDING BUT NOT LIMITED TO ANY RIGHTS UNDER APPLICABLE CONSUMER
            PROTECTION REGULATION), TO THE EXTENT THESE MAY NOT BE EXCLUDED OR WAIVED UNDER APPLICABLE LAWS AND
            REGULATIONS.</p>
        <p><br></p>
        <p>YOU AGREE TO INDEMNIFY US, OUR PARTNERS AND OUR AFFILIATES FROM AND AGAINST ANY CLAIM, SUIT, ACTION, DEMAND,
            DAMAGE, DEBT, LOSS, COST, EXPENSE (INCLUDING BUT NOT LIMITED TO LITIGATION COSTS AND ATTORNEYS’ FEES) AND
            LIABILITY ARISING FROM: (I) YOUR USE OF OUR SERVICES OR SOFTWARE; OR (II) YOUR BREACH OF THIS EULA.</p>
        <p><br></p>
        <p>TECHNICAL SUPPORT</p>
        <p><br></p>
        <p>We may in our discretion provide technical support for the Licensed Items (whether for free or for a fee). We
            provide technical support without any guaranty or warranty of any kind and subject always to the “Warranty
            and Disclaimer” and “Liability for our Services” sections above.</p>
        <p><br></p>
        <p>PAYMENTS, FEES AND CHARGES</p>
        <p><br></p>
        <p>You may from time to time make payments to us or other third parties in relation to the Licensed Items. You
            agree that all such payments from you are subject to, and you will comply with, all terms and conditions of
            the relevant payment service (whether that payment service provider is us or a third party), in addition to
            any other relevant terms of this EULA. We bear no responsibility for any transactions processed by, or any
            payments made to, a third party, whether in connection with the relevant Licensed Item or not. You agree
            that you are solely responsible for all fees and taxes associated with any Licensed Items, and that pricing
            and availability of all Licensed Items are subject to change at any time.</p>
        <p><br></p>
        <p>You agree that any payments you make to us in exchange for your use of any Licensed Items (for example, for
            the grant of a License to use any Virtual Currency, Virtual Goods or Content) is final and non-refundable,
            except where otherwise specified by us for a particular Licensed Item or as specified under the “Term,
            Suspension and Termination” section below. SUBJECT TO MANDATORY APPLICABLE LAWS AND REGULATIONS OR AS
            OTHERWISE SPECIFIED BY US FOR A PARTICULAR LICENSED ITEM, IN NO CIRCUMSTANCES WILL WE BE REQUIRED TO PROVIDE
            A REFUND FOR ANY PAYMENTS MADE BY YOU TO US IN RELATION TO ANY LICENSED ITEM (WHETHER USED OR UNUSED).</p>
        <p><br></p>
        <p>Please be aware that you may incur telecommunications charges and other fees and expenses (charged by the
            relevant third parties directly or indirectly) in your use of the Licensed Items. You are responsible for
            paying all such charges, fees and expenses. We will not be responsible for the quality of services provided
            by such third parties.</p>
        <p><br></p>
        <p>VIRTUAL GOODS AND VIRTUAL CURRENCY</p>
        <p><br></p>
        <p>Some Software may include functionality which allows you to purchase and use virtual goods (for instance,
            additional characters or weapons within our games) (“Virtual Goods”). We grant you a limited, personal,
            non-exclusive, non-sublicensable, non-transferrable, revocable License to use such Virtual Goods within the
            Software in accordance with any requirements set out in the Instructions and in this EULA. Unless otherwise
            specified in the Software, all Virtual Goods will be deemed to be an integral part of the Software and
            therefore be a Licensed Item.</p>
        <p><br></p>
        <p>Virtual Goods may be licensed to you upon payment by you of:</p>
        <p><br></p>
        <p>-“real world money”, or by using separate activation codes as applicable from time to time; and/or</p>
        <p><br></p>
        <p>-virtual currency purchased, earned or obtained through your use of the Software or from any platform we
            enable for this purpose from time to time (“Virtual Currency”).</p>
        <p><br></p>
        <p>You acknowledge that you do not own the Virtual Goods and/or the Virtual Currency. They do not represent any
            credit balance of “real world money” or the equivalent, and cannot be redeemed for “real world money” or
            anything of monetary value. Virtual Goods and Virtual Currency are provided solely for your enjoyment of the
            Software. All sales of Virtual Goods and Virtual Currency by us to you are final.</p>
        <p><br></p>
        <p>You will not trade, sell, gift, transfer or lend Virtual Goods or Virtual Currency (a “Virtual Transaction”),
            except as permitted in accordance with the Instructions. Where the Instructions permit you to engage in
            Virtual Transactions any transactions are between you and the other relevant parties. We have no
            responsibility for any Virtual Transactions, redemptions or refunds of Virtual Goods and/or Virtual
            Currency. We are not responsible for any claims brought against you in relation to your use of the Virtual
            Goods and/or Virtual Currency, relating to any Virtual Transactions you enter into or are alleged to have
            entered into.</p>
        <p><br></p>
        <p>We may require you to comply with specific policies applicable to the purchase and use of Virtual Goods and
            Virtual Currency and we may change these policies from time to time. All such policies will form part of
            this EULA. We may modify or eliminate Virtual Goods and/or Virtual Currency from some or all Software at any
            time, with or without notice. We will have no liability to you in the event that we exercise these rights.
            If we close Your Account, terminate your access to the Software, or terminate this EULA, you will forfeit
            all Virtual Currency and Virtual Goods, and we will have no liability to you for that forfeiture.</p>
        <p><br></p>
        <p>OUR INTELLECTUAL PROPERTY RIGHTS</p>
        <p><br></p>
        <p>All intellectual property rights in or to the Licensed Items and our other Services and software (including
            but not limited to any future updates, upgrades and new versions) will continue to belong to us and our
            licensors. Except as expressly provided in this EULA, you have no right to use our intellectual property
            rights. In particular, you have no right to use our trademarks, brand name, game character design, music,
            image, video, storyline, logo, domain names, distinctive brand features, and any other of our proprietary
            information or the layout or design of any page without our prior written consent. Any comments or
            suggestions you may provide through our Services or the platforms of our Services (i.e. user forum)
            regarding our Services are entirely voluntary and we will be free to use these comments and suggestions at
            our discretion without any payment or other obligation to you.</p>
        <p><br></p>
        <p>THIRD PARTY SOFTWARE, CONTENT AND SERVICES</p>
        <p><br></p>
        <p>We are not responsible for and we do not endorse, support or guarantee the lawfulness, accuracy or
            reliability of any content submitted to, transmitted or displayed by or linked by our Software or Services,
            including but not limited to any Your Content or other content provided by other users of our Software or
            Services or by our advertisers. You acknowledge and agree that by using our Software or Services you may be
            exposed to content which is inaccurate, misleading, defamatory, offensive or unlawful. Any reliance on or
            use of any content on or accessible from our Software or Services by you is at your own risk. Your use of
            our Software or Services does not give you any rights in or to any content you may access or obtain in
            connection with your use of our Software or Services.</p>
        <p><br></p>
        <p>We also do not guarantee the quality, reliability or suitability of any third party services provided, made
            available, advertised or linked through our Software or Services, and we will bear no responsibility for
            your use of or relationship with any such third party services. If you access third party services through
            our Software or Services, you must comply with any terms and conditions applicable to those services.</p>
        <p><br></p>
        <p>We may review (but make no commitment to review) content or third party services made available through our
            Software or Services to determine whether or not they comply with our policies, applicable laws and
            regulations or are otherwise objectionable. We may remove or refuse to make available or link to certain
            content or third party services if they infringe intellectual property rights, are obscene, defamatory or
            abusive, violate any rights or pose any risk to the security or performance of our Software or Services.</p>
        <p><br></p>
        <p>There may be, from time to time, third party content and services made available to you through our Software
            or Services that are subject to further terms, including but not limited to terms from the relevant third
            party that originally produced such content and services. In such cases, you agree to comply with any such
            further terms and conditions as notified to you in relation to such third party content and services.</p>
        <p><br></p>
        <p>In addition, please note that we are not responsible for and we do not endorse, support or guarantee the
            quality, reliability or suitability of any content, software, plug-ins, tools or data supplied by third
            parties for use in connection with the Licensed Items. Any reliance on or use of such content, software,
            tools, plug-ins or data is at your own risk. You must comply with any additional terms and conditions
            applicable to any third party content, software, tools, plug-ins and data.</p>
        <p><br></p>
        <p>OPEN SOURCE SOFTWARE</p>
        <p><br></p>
        <p>Some Licensed Items may contain software that is subject to “open source” Licenses (the “Open Source
            Software”). Where we use such Open Source Software, please note that there may be provisions in the Open
            Source Software’s License that expressly override this EULA, in which case such provisions shall prevail to
            the extent of any conflict with this EULA.</p>
        <p><br></p>
        <p>USE OF YOUR DEVICE BY OUR SOFTWARE AND SERVICES</p>
        <p><br></p>
        <p>In order for us to provide the Software and Services to you, we may require access to and/or use of your
            relevant device (such as mobile phone, tablet or desktop computer) that you use to access that Software
            and/or Service – for example, we may need to use your device’s processor and storage to complete the
            relevant Software installation, or we may need to access your contact list to provide certain interactive
            functions within our apps. You agree to give us such access to and use of your device.</p>
        <p><br></p>
        <p>We will provide further information regarding how a particular item of Software or particular Service uses
            and accesses your device within that relevant Software or Service or in another manner (e.g. via the
            relevant app store as part of the relevant service’s installation process). You understand that if you do
            not provide us with such right of use or access, we may not be able to provide the relevant Software or
            Service to you.</p>
        <p><br></p>
        <p>EXPORT REGULATIONS</p>
        <p><br></p>
        <p>You acknowledge and agree that the Licensed Items may from time to time be subject to the import and export
            laws of certain countries where Licensed Items are imported or re-exported. You agree to comply with all
            relevant laws relating to the import, export and re-export of the Licensed Items and, in particular, will
            not export any Licensed Items to any prohibited country, entity, or person for which an export License or
            other governmental approval is required unless and until the License or other governmental approval has been
            obtained.</p>
        <p><br></p>
        <p>BREACH AND INDEMNIFICATION</p>
        <p><br></p>
        <p>In case we reasonably find that you have breached this EULA, we may take such actions as we deem appropriate,
            including but not limited to: (i) removing Your Content that are involved; (ii) restoring your game data to
            the status before your breach; (iii) terminating your right to use our Services; (iv) taking legal action
            against you or disclosing relevant information to law enforcement authorities and (v) any other actions set
            forth in any posted guidelines and rules.</p>
        <p><br></p>
        <p>You will indemnify and hold us harmless from any and all third party claims, losses, damages, liabilities,
            costs, and expenses, relating to or arising under or out of the relationship between you and us described in
            this EULA, including but not limited to any breach of this EULA. You hereby agree that we shall have the
            right to control the legal defense against any such claims, demands, or litigation, including but not
            limited to the right to select counsel of our choice and to compromise or settle any such claims, demands,
            or litigation.</p>
        <p><br></p>
        <p>TERM, SUSPENSION AND TERMINATION</p>
        <p><br></p>
        <p>The License granted to you under this EULA will commence on the earlier of the date that you: (i) accept the
            terms and conditions of the EULA; or (ii) download, copy, install, access or use any Licensed Items. The
            License will expire with respect to each Licensed Item on the date upon which you dispose of the Licensed
            Item or the termination date, if terminated by us, as set out below.</p>
        <p><br></p>
        <p>We may suspend your access to or use of any Licensed Item or terminate this EULA if:</p>
        <p><br></p>
        <p>(1)we reasonably believe that you have breached any term or condition of this EULA;</p>
        <p><br></p>
        <p>(2)your use of the Licensed Items creates risk for us or for other users, gives rise to a threat of potential
            third party claims against us or is potentially damaging to our reputation;</p>
        <p><br></p>
        <p>(3)you fail to use the Licensed Items for a prolonged period;</p>
        <p><br></p>
        <p>(4)you attempt to circumvent the technical protection measures for the Licensed Items;</p>
        <p><br></p>
        <p>(5)we reasonably believe that you have not accessed or used the Licensed Items for a duration of three (3) or
            more consecutive months from the date and time of last recorded access and/or use on our servers;</p>
        <p><br></p>
        <p>(6)where we cease to support that Licensed Item; or</p>
        <p><br></p>
        <p>(7)for any other reason, such as (a) there is a regulatory or statutory change limiting our ability to
            provide such part of the Licensed Items or the Services, and (b) any event (such as a technical difficulty,
            capacity problem or communications failure) beyond our reasonable control prevents us from continuing to
            provide such part of the Licensed Items or the Services.</p>
        <p><br></p>
        <p>Where you have paid any subscription fee in relation to a Licensed Item and we terminate this EULA in
            relation to that Licensed Item under paragraphs (6) or (7) in circumstances not due to any act or omission
            or breach of this EULA by you or not due to any applicable laws or regulations, we will (i) refund a
            pro-rata proportion of any subscription fees already paid by you at the time of termination, or (ii) provide
            other form of compensation to you, as permitted by the applicable laws. Where reasonably practicable, we
            will give you notice of any suspension or termination.</p>
        <p><br></p>
        <p>Suspension or termination of your License to the relevant Licensed Item or this EULA will not limit any of
            our rights or remedies at law or in equity.</p>
        <p><br></p>
        <p>Upon expiry or termination of this EULA (in whole or in respect of any particular Licensed Item), you will
            immediately permanently delete all copies of the Licensed Item to which the expiry or termination relates
            and you will immediately cease accessing and using any Software and Services relating to that Licensed
            Item.</p>
        <p><br></p>
        <p>Please note that Licensed Items may be automatically deleted from your device, or otherwise made unavailable
            to you, once the time period for which the Licensed Item is available to you has expired.</p>
        <p><br></p>
        <p>RETENTION AND BACK-UP OF YOUR DATA</p>
        <p><br></p>
        <p>We do not guarantee that we will be able to return any data, information, media or other content submitted,
            uploaded, transmitted or displayed by you using the Licensed Items back to you following suspension or
            termination of this EULA or after you cease using the Licensed Items. We may permanently delete such data,
            information, media or other content without notice to you at any time after termination. Please ensure that
            you regularly back up data, information, media and other content.</p>
        <p><br></p>
        <p>YOUR STATUTORY RIGHTS</p>
        <p><br></p>
        <p>Nothing in this EULA is intended to replace or supersede your rights under any mandatory applicable laws and
            regulations, and such mandatory rights of yours will apply to the extent they are inconsistent with any
            terms in this EULA.</p>
        <p><br></p>
        <p>GENERAL</p>
        <p><br></p>
        <p>This EULA (including the Privacy Policy and the Instructions) is the entire agreement between you and us in
            relation to our Services. You agree that you will have no claim against us for any statement which is not
            explicitly set out in this EULA. The invalidity of any provision of this EULA (or parts of any provision)
            will not affect the validity or enforceability of any other provision (or the remaining parts of that
            provision). If a court holds that we cannot enforce any part of this EULA as drafted, we may replace those
            terms with similar terms to the extent enforceable under applicable laws and regulations, without changing
            the remaining terms of this EULA. No delay in enforcing any provision of this EULA will be construed to be a
            waiver of any rights under that provision. Any rights and obligations under this EULA which by their nature
            should survive, including but not limited to any obligations in relation to the liability of, or indemnities
            (if any) given by, the respective parties, will remain in effect after termination or expiration of this
            EULA.</p>
        <p><br></p>
        <p>No person other than you and us will (subject to any applicable laws and regulations) have any right to
            enforce this EULA against any person, and you may not delegate, assign or transfer this EULA or any rights
            or obligations under this EULA, without our prior consent. We may freely assign, transfer or sub-contract
            this EULA or our rights and obligations under this EULA, in whole or in part, without your prior consent or
            notice. You acknowledge and agree that in no event will our partners or Affiliates have any liability under
            this EULA.</p>
        <p><br></p>
        <p>GOVERNING LAW AND DISPUTE RESOLUTION</p>
        <p><br></p>
        <p>Except to the extent that the applicable laws and regulations of your jurisdiction mandate otherwise (for
            instance, you may have statutory rights in your jurisdiction in relation to bringing or defending claims in
            a local court (including small claims court (or similar court)), this EULA and any dispute or claim arising
            out of or in connection with this EULA will be governed by the law of the Hong Kong Special Administrative
            Region. Any dispute, controversy or claim (whether in contract, tort or otherwise) arising out of, relating
            to, or in connection with this EULA, including but not limited to their existence, validity, interpretation,
            performance, breach or termination, will be referred to and finally resolved by arbitration administered by
            the Hong Kong International Arbitration Centre under the Hong Kong International Arbitration Centre
            Administered Arbitration rules in force when the Notice of Arbitration is submitted. The seat of the
            arbitration will be Hong Kong. There will be one arbitrator only. The arbitration proceedings will be
            conducted in Chinese.</p>
        <p><br></p>
        <p><br></p>
        <p><br></p>
        <p><br></p>
</div>
</template>

<script>
	import toolsData from '@/assets/tools.json';

	export default {
		data() {
			return {
				categories: toolsData.categories
			}
		},
		methods: {
			getToolsCount() {
				let count = 0;
				this.categories.forEach(category => {
					count += category.tools.length;
				});
				return count;
			}
		}
	};
</script>

<style lang="scss">
	.about-container {
		a {
			color: #007bff;
			text-decoration: none;
		}

		.gitee svg {
			animation: spin 2s linear infinite;
		}

		@keyframes spin {
			from {
				transform: rotate(0deg);
			}

			to {
				transform: rotate(360deg);
			}
		}

		table {
			width: 80%;
			border-collapse: collapse;
			margin-bottom: 20px;
		}

		th,
		td {
			padding: 10px;
			text-align: left;
			border: 1px solid #ddd;
		}

		th {
			background-color: #f2f2f2;
			font-weight: bold;
			text-transform: uppercase;
		}

		tbody tr:nth-child(even) {
			background-color: #f9f9f9;
		}

		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}

		li {
			margin-bottom: 5px;
		}
	}
</style>
