<template>
	<div id="app">
		<div class="tool-header" v-if="$route.path !== '/'">
			<router-link to="/" style="color: #007bff;text-decoration: none;"></router-link>
		</div>
		<div class="nav">
			<div class="logo">
				<div><img src="./assets/images/工具箱.png" alt=""></div>
				<span>Quick-tool Kit</span>
			</div>
			<p>A convenient tool library</p>
		</div>

		<router-view></router-view>
		
		<div class="footer">
			<div class="privace">
				<span @click="dialogVisible = true">Privacy</span>
				<span @click="dialogVisible2 = true">Service</span>
			</div>
			<p>Contact Email: l931490399@gmail.com   </p>

		</div>
		<el-dialog
			title="privacy"
			:visible.sync="dialogVisible"
			width="80%"
			:before-close="handleClose">
			<privacy></privacy>
			
		</el-dialog>
		<el-dialog
			title="Service"
			:visible.sync="dialogVisible2"
			width="80%"
			:before-close="handleClose">
			<Service></Service>
			
		</el-dialog>
	</div>
</template>

<script>
	export default {
		components:{
			Service: () => import('./components/Privacy/service.vue'),
			privacy: () => import('./components/Privacy/privacy.vue'),
		},
		name: 'App',
		data() {
			return {
				dialogVisible: false,
				dialogVisible2: false,
			}
		},
	}
</script>
<style>
</style>
